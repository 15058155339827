import { useState, useRef } from 'react';
import { useTranslation } from '../i18n/i18n';
import { MenuButton, ControlledMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import './assets/navigation.css';

export const Navigation = (props) => {
  const { t, i18n } = useTranslation();
  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const ref = useRef(null);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container" style={{ position: 'relative' }}>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {' '}
            <span className="sr-only">Toggle navigation</span>{' '}
            <span className="icon-bar"></span>{' '}
            <span className="icon-bar"></span>{' '}
            <span className="icon-bar"></span>{' '}
          </button>
          <a href="#page-top">
            <img src="img/main-logo.jpg" width={100} alt="CTL Cargo" />
          </a>
        </div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right principal-nav-bar">
            <li>
              <a href="#home" className="page-scroll">
                {t('common.home')}
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                {t('common.aboutUs')}
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                {t('common.services')}
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                {t('common.contactUs')}
              </a>
            </li>
          </ul>
        </div>
        <MenuButton
          ref={ref}
          className="btn"
          onClick={() => setIsLanguageMenuOpen(true)}
        >
          {i18n.language === 'en' ? (
            <img src="img/en-flag.ico" width={25} alt="en flag" />
          ) : (
            <img src="img/es-flag.ico" width={25} alt="es flag" />
          )}
          <i style={{ marginLeft: 4 }} className="fa fa-chevron-down"></i>
        </MenuButton>
        <ControlledMenu
          state={isLanguageMenuOpen ? 'open' : 'closed'}
          anchorRef={ref}
          onClose={() => setIsLanguageMenuOpen(false)}
          onClick={() => setIsLanguageMenuOpen(false)}
        >
          <div
            className="language-radio-group-container"
            onChange={handleChangeLanguage}
          >
            <div>
              <input
                id="en-EN"
                defaultChecked={i18n.language === 'en'}
                type="radio"
                value="en"
                name="language"
              />
              <label htmlFor="en-EN">English - EN</label>
            </div>
            <br />
            <div>
              <input
                id="es-ES"
                defaultChecked={i18n.language === 'es'}
                type="radio"
                value="es"
                name="language"
              />{' '}
              <label htmlFor="es-ES">Español - ES</label>
            </div>
          </div>
        </ControlledMenu>
      </div>
    </nav>
  );
};
